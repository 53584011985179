import React from "react";
import { Link } from "gatsby";
import useWindowDimensions from "../hooks/use-window-dimensions";

const MediaListing = ({
  title,
  subtitle,
  date,
  image,
  category,
  excerpt,
  slug,
}) => {
  const { windowWidth } = useWindowDimensions();

  const months = {
    January: "01",
    Febuary: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const desktopDate = date;
  const dateArray = date.split(" ");
  const month = months[dateArray[0]];
  const year = dateArray[2];

  const mobileDate = month + " / " + year;
  return (
    <div className="media-listing grid">
      <div className="about">
        <div className="content">
          <h2 className="link">
            <Link to={slug}>
              {title}
              <br />
              {subtitle}
            </Link>
          </h2>
          {windowWidth > 991 ? <p className="description">{excerpt}</p> : null}
        </div>
      </div>
      <div className="image">
        <Link to={slug}>
          <img src={image} alt="" />
        </Link>
      </div>
      {windowWidth > 991 ? null : <p className="description">{excerpt}</p>}
      <p className="date">{windowWidth > 991 ? desktopDate : mobileDate}</p>
      {windowWidth > 991 ? <p className="category">{category}</p> : null}
    </div>
  );
};

export default MediaListing;
