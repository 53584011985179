const { BREAKPOINTS } = require("./constants/constants");

const isUndefined = function (x) {
  return typeof x === "undefined";
};

const isNotUndefined = function (x) {
  return typeof x !== "undefined";
};

const trimEndNonAlphanumeric = function (string) {
  while (isNotLetterOrNumber(string.charAt(string.length - 1))) {
    string = string.substring(0, string.length - 1);
  }
  return string;
};

const isNotLetterOrNumber = function (character) {
  return !character.match(/^[0-9a-zA-Z]+$|_/);
};

const hasWindow = function () {
  return typeof window !== "undefined";
};

const isMobile = function (windowWidth) {
  return windowWidth <= BREAKPOINTS.EXTRA_SMALL_MAX_WIDTH;
};

const isNotMobile = function (windowWidth) {
  return BREAKPOINTS.EXTRA_SMALL_MAX_WIDTH < windowWidth;
};

const isExtraSmallBreakPoint = function (windowWidth) {
  return windowWidth <= BREAKPOINTS.EXTRA_SMALL_MAX_WIDTH;
};

const isSmallBreakPoint = function (windowWidth) {
  return (
    BREAKPOINTS.EXTRA_SMALL_MAX_WIDTH < windowWidth &&
    windowWidth <= BREAKPOINTS.SMALL_MAX_WIDTH
  );
};

const isMediumBreakPoint = function (windowWidth) {
  return (
    BREAKPOINTS.SMALL_MAX_WIDTH < windowWidth &&
    windowWidth <= BREAKPOINTS.MEDIUM_MAX_WIDTH
  );
};

const isLargeBreakPoint = function (windowWidth) {
  return windowWidth > BREAKPOINTS.MEDIUM_MAX_WIDTH;
};

module.exports = {
  isUndefined,
  isNotUndefined,
  trimEndNonAlphanumeric,
  isNotLetterOrNumber,
  hasWindow,
  isMobile,
  isNotMobile,
  isExtraSmallBreakPoint,
  isSmallBreakPoint,
  isMediumBreakPoint,
  isLargeBreakPoint,
};
