import { useState, useEffect } from "react";
import { hasWindow } from "../../utils/utils";

export default function useWindowDimensions() {
  function getWindowDimensions() {
    const windowWidth = hasWindow() ? window.innerWidth : null;
    const windowheight = hasWindow() ? window.innerHeight : null;
    return {
      windowWidth,
      windowheight,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow()) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return windowDimensions;
}
