import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/global/layout/layout";
import Seo from "../../components/global/seo/seo";

import MediaListing from "../../components/media/media-listing";

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="Media" />
      <div className="padded">
        <h1 className="page-title">Media</h1>
        <div className="media-listings">
          {posts.map((post, index) => (
            <MediaListing
              title={post.frontmatter.title}
              subtitle={post.frontmatter.subtitle}
              date={post.frontmatter.date}
              image={post.frontmatter.featuredImages[0]}
              category={post.frontmatter.category}
              excerpt={post.excerpt}
              slug={post.fields.slug}
              key={index}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          subtitle
          date(formatString: "MMMM DD YYYY")
          featuredImages
          category
        }
      }
    }
  }
`;
