const ALGOLIA_INDICES = {
  ASCENDING: "designburger_date_asc",
  DECENDING: "designburger_date_desc",
};

const BREAKPOINTS = {
  EXTRA_SMALL_MAX_WIDTH: 767,
  SMALL_MAX_WIDTH: 991,
  MEDIUM_MAX_WIDTH_: 1199,
};

module.exports = { ALGOLIA_INDICES, BREAKPOINTS };
